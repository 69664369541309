// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-5-8-xronon-commercial-jazz-js": () => import("./../../../src/pages/5-8-xronon/commercial-jazz.js" /* webpackChunkName: "component---src-pages-5-8-xronon-commercial-jazz-js" */),
  "component---src-pages-5-8-xronon-klasiko-mpaleto-js": () => import("./../../../src/pages/5-8-xronon/klasiko-mpaleto.js" /* webpackChunkName: "component---src-pages-5-8-xronon-klasiko-mpaleto-js" */),
  "component---src-pages-5-8-xronon-musical-dance-js": () => import("./../../../src/pages/5-8-xronon/musical-dance.js" /* webpackChunkName: "component---src-pages-5-8-xronon-musical-dance-js" */),
  "component---src-pages-5-8-xronon-musical-mousiki-agogi-js": () => import("./../../../src/pages/5-8-xronon/musical-mousiki-agogi.js" /* webpackChunkName: "component---src-pages-5-8-xronon-musical-mousiki-agogi-js" */),
  "component---src-pages-8-16-xronon-autosxediasmos-js": () => import("./../../../src/pages/8-16-xronon/autosxediasmos.js" /* webpackChunkName: "component---src-pages-8-16-xronon-autosxediasmos-js" */),
  "component---src-pages-8-16-xronon-breakdance-js": () => import("./../../../src/pages/8-16-xronon/breakdance.js" /* webpackChunkName: "component---src-pages-8-16-xronon-breakdance-js" */),
  "component---src-pages-8-16-xronon-commercial-jazz-js": () => import("./../../../src/pages/8-16-xronon/commercial-jazz.js" /* webpackChunkName: "component---src-pages-8-16-xronon-commercial-jazz-js" */),
  "component---src-pages-8-16-xronon-hip-hop-js": () => import("./../../../src/pages/8-16-xronon/hip-hop.js" /* webpackChunkName: "component---src-pages-8-16-xronon-hip-hop-js" */),
  "component---src-pages-8-16-xronon-mpaleto-degre-i-ii-iii-sto-rosiko-tmima-vaganova-js": () => import("./../../../src/pages/8-16-xronon/mpaleto-degre-i-ii-iii-sto-rosiko-tmima-vaganova.js" /* webpackChunkName: "component---src-pages-8-16-xronon-mpaleto-degre-i-ii-iii-sto-rosiko-tmima-vaganova-js" */),
  "component---src-pages-8-16-xronon-musical-mousiki-agogi-js": () => import("./../../../src/pages/8-16-xronon/musical-mousiki-agogi.js" /* webpackChunkName: "component---src-pages-8-16-xronon-musical-mousiki-agogi-js" */),
  "component---src-pages-8-16-xronon-sygxronos-xoros-js": () => import("./../../../src/pages/8-16-xronon/sygxronos-xoros.js" /* webpackChunkName: "component---src-pages-8-16-xronon-sygxronos-xoros-js" */),
  "component---src-pages-adult-aerial-js": () => import("./../../../src/pages/adult/aerial.js" /* webpackChunkName: "component---src-pages-adult-aerial-js" */),
  "component---src-pages-adult-commercial-jazz-js": () => import("./../../../src/pages/adult/commercial-jazz.js" /* webpackChunkName: "component---src-pages-adult-commercial-jazz-js" */),
  "component---src-pages-adult-express-yourself-js": () => import("./../../../src/pages/adult/express-yourself.js" /* webpackChunkName: "component---src-pages-adult-express-yourself-js" */),
  "component---src-pages-adult-hip-hop-js": () => import("./../../../src/pages/adult/hip-hop.js" /* webpackChunkName: "component---src-pages-adult-hip-hop-js" */),
  "component---src-pages-adult-klasiko-mpaleto-js": () => import("./../../../src/pages/adult/klasiko-mpaleto.js" /* webpackChunkName: "component---src-pages-adult-klasiko-mpaleto-js" */),
  "component---src-pages-adult-latin-js": () => import("./../../../src/pages/adult/latin.js" /* webpackChunkName: "component---src-pages-adult-latin-js" */),
  "component---src-pages-adult-sygxronos-xoros-js": () => import("./../../../src/pages/adult/sygxronos-xoros.js" /* webpackChunkName: "component---src-pages-adult-sygxronos-xoros-js" */),
  "component---src-pages-adult-yoga-js": () => import("./../../../src/pages/adult/yoga.js" /* webpackChunkName: "component---src-pages-adult-yoga-js" */),
  "component---src-pages-architecture-js": () => import("./../../../src/pages/architecture.js" /* webpackChunkName: "component---src-pages-architecture-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-eos-5-xronon-commercial-jazz-babies-js": () => import("./../../../src/pages/eos-5-xronon/commercial-jazz-babies.js" /* webpackChunkName: "component---src-pages-eos-5-xronon-commercial-jazz-babies-js" */),
  "component---src-pages-eos-5-xronon-mousikokinitiki-agogi-js": () => import("./../../../src/pages/eos-5-xronon/mousikokinitiki-agogi.js" /* webpackChunkName: "component---src-pages-eos-5-xronon-mousikokinitiki-agogi-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-musical-theater-js": () => import("./../../../src/pages/musical-theater.js" /* webpackChunkName: "component---src-pages-musical-theater-js" */),
  "component---src-pages-philosophy-js": () => import("./../../../src/pages/philosophy.js" /* webpackChunkName: "component---src-pages-philosophy-js" */),
  "component---src-pages-program-js": () => import("./../../../src/pages/program.js" /* webpackChunkName: "component---src-pages-program-js" */),
  "component---src-pages-royal-academy-of-dance-js": () => import("./../../../src/pages/royal-academy-of-dance.js" /* webpackChunkName: "component---src-pages-royal-academy-of-dance-js" */),
  "component---src-pages-school-js": () => import("./../../../src/pages/school.js" /* webpackChunkName: "component---src-pages-school-js" */),
  "component---src-pages-studios-js": () => import("./../../../src/pages/studios.js" /* webpackChunkName: "component---src-pages-studios-js" */),
  "component---src-pages-summer-dancing-js": () => import("./../../../src/pages/summer-dancing.js" /* webpackChunkName: "component---src-pages-summer-dancing-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-templates-person-js": () => import("./../../../src/templates/person.js" /* webpackChunkName: "component---src-templates-person-js" */)
}

